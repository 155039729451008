<template>
    <p v-html="privacy" style="margin: 0">哈哈哈哈</p>
</template>

<script>
    import {UserAgreement, PrivacyContent} from '@/assets/js/user-privacy-data.json'

    export default {
        name: "",
        data(){
            return {
                privacy: "privacy",
            }
        },
        mounted() {
            this.init()
        },
        methods:{
            init(){
                this.privacy = this.$route.query.isUser ? UserAgreement : PrivacyContent
            }
        }
    }
</script>
